export default function formatEventDateISO(dates: {
  startDate?: string;
  endDate?: string;
}): string {
  let formatedDate = '';

  function parseISODate(dateStr: string): Date | undefined {
    const parts = dateStr.split('-');
    const year = parts[0] ? parseInt(parts[0]) : undefined;
    const month = parts[1] ? parseInt(parts[1]) - 1 : undefined;
    const day = parts[2] ? parseInt(parts[2]) : undefined;
    return year && month !== undefined && day ? new Date(year, month, day) : undefined;
  }

  const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'long', year: 'numeric' };
  const locale = 'fr-FR';

  if (dates.startDate && dates.endDate && dates.startDate !== dates.endDate) {
    const startDateFormat = parseISODate(dates.startDate);
    const endDateFormat = parseISODate(dates.endDate);

    const startDateFormatted = new Intl.DateTimeFormat(locale, {
      ...options,
      year: undefined
    }).format(startDateFormat);
    const endDateFormatted = new Intl.DateTimeFormat(locale, options).format(endDateFormat);

    formatedDate = `Du ${startDateFormatted} au ${endDateFormatted}`;
  } else if (dates.startDate) {
    const startDateFormat = parseISODate(dates.startDate);
    const startDateFormatted = new Intl.DateTimeFormat(locale, options).format(startDateFormat);

    formatedDate = `Le ${startDateFormatted}`;
  }

  return formatedDate;
}
